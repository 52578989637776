
/**
 * This function is a hook that can determine if the component
 * is currently mounted in the DOM or not. The hook uses the useRef
 * hook to create a mutable isMounted ref that is initially set to
 * false. Then, it uses the useEffect hook to set isMounted to true
 * when the component is mounted and set it back to false when the
 * component is unmounted. Finally, it returns a callback function
 * that returns the current value of isMounted.
 */

import { useCallback, useEffect, useRef } from 'react';

export const useMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};
