import type { NextPage } from 'next';
import Head from 'next/head';
import NextLink from 'next/link';
import { Box, Grid, Typography } from '@mui/material';
import { GuestGuard } from '@/components/authentication/guest-guard';
import LoginImage from '@/assets/images/screenshots/login-image.png';
import Image from 'next/image';
import { Logo } from '@/assets/logos/logo';
import { RtkLogin } from '@/components/authentication/rtk-login';
import CopyrightTerms from '@/components/_shared/layouts/main/copyright-terms';

const Login: NextPage = () => {
  return (
    <>
      <Head>
        <title>Login | RipeMetrics</title>
      </Head>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto'
        }}
      >
        <Grid
          container
          sx={{ flex: '1 1 auto' }}
        >
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              backgroundColor: 'neutral.50',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                p: 3
              }}
            >
              <NextLink
                href="https://www.ripemetrics.com/"
                passHref
              >
                <Logo />
              </NextLink>
            </Box>
            <Box
              sx={{
                flex: '1 1 auto',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  maxWidth: 500,
                  px: 3,
                  py: '100px',
                  width: '100%'
                }}
              >
                <div>
                  <Typography
                    sx={{ mb: 1 }}
                    variant="h4"
                  >
                    Welcome
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{ mb: 3 }}
                    variant="body2"
                  >
                    Sign in to RipeMetrics
                  </Typography>
                  <RtkLogin />
                </div>
              </Box>
            </Box>
            <div className="my-4">
              <CopyrightTerms />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              alignItems: 'center',
              background:
                'radial-gradient(50% 50% at 50% 50%, #eab308 0%, #FF792A 100%)',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              '& img': {
                maxWidth: '100%'
              }
            }}
          >
            <Box sx={{ p: 3 }}>
              <Typography
                align="center"
                color="inherit"
                sx={{
                  fontSize: '16px',
                  lineHeight: '32px',
                  mb: 1
                }}
              >
                Not on RipeMetrics yet?&nbsp;&nbsp;
              </Typography>
              <h2 className="text-center font-proximaSemiBold text-4xl tracking-tight text-white sm:text-4xl">
                <span className="block">
                  Deliver Experiences That Drive Revenue
                </span>
              </h2>
              <Image
                alt=""
                src={LoginImage}
              />
              <div className="mx-auto max-w-2xl pb-6 text-center sm:px-6 lg:px-8">
                <h2 className="text-center text-2xl font-semibold text-white">
                  All-in-One: Email, SMS, and Business Intelligence
                </h2>
                <div className="mt-4 text-lg leading-6 text-orange-50">
                  Log in today to experience a unified customer experience
                  platform that builds brand loyalty, drives conversions, and
                  helps scale your business.
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Login.getLayout = (page) => <GuestGuard>{page}</GuestGuard>;

export default Login;
